import React, { useState, useEffect } from 'react';
import { createTask, getStaffForLead, getStaffList } from '../services/api';

const TaskCreate = ({ authToken, contentType, patientId, onTaskCreated }) => {
  const [task, setTask] = useState({
    title: '',
    description: '',
    due_date: '',
    status: 'pending',
    assigned_to: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    console.log('contentType:', contentType);
    const fetchStaffList = async () => {
      try {
        let staff = null;
        if (contentType == 'leads'){
          staff = await getStaffForLead(authToken, patientId);
        }
        else {
          staff = await getStaffList(authToken);
        }
        setStaffList(staff);
      } catch (error) {
        console.error('Error fetching staff list:', error);
      }
    };
    fetchStaffList();
  }, [authToken]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setTask(prevTask => ({
      ...prevTask,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!task.title.trim() || !task.description.trim() || !task.due_date) {
      setError('All fields are required');
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      await createTask(authToken, contentType, patientId, task);
      setTask({
        title: '',
        description: '',
        due_date: '',
        status: 'pending',
        assigned_to: '',
      });
      if (onTaskCreated) onTaskCreated();
    } catch (error) {
      console.error('Error creating task:', error);
      setError('Failed to create task. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-4">
      <h3 className="text-lg font-bold mb-2">Create New Task</h3>
      {error && <div className="text-red-500 mb-2">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="title"
          >
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={task.title}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            placeholder="Enter task title"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="description"
          >
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={task.description}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            rows="4"
            placeholder="Enter task description"
          ></textarea>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="due_date"
          >
            Due Date
          </label>
          <input
            type="date"
            id="due_date"
            name="due_date"
            value={task.due_date}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="status"
          >
            Status
          </label>
          <select
            id="status"
            name="status"
            value={task.status}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          >
            <option value="pending">Pending</option>
            <option value="in_progress">In Progress</option>
            <option value="completed">Completed</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="assigned_to"
          >
            Assigned To
          </label>
          <select
            id="assigned_to"
            name="assigned_to"
            value={task.assigned_to}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          >
            <option value="">Select staff member</option>
            {staffList.map((staff) => (
              <option key={staff.id} value={staff.id}>
                {staff.user.username}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className="mt-2 bg-btn-primary hover:bg-btn-primary-hover text-sm font-medium text-white py-2 px-4 rounded"
          disabled={isLoading}
        >
          {isLoading ? "Creating..." : "Create Task"}
        </button>
      </form>
    </div>
  );
};

export default TaskCreate;