import React, { useState, useEffect } from "react";
import { getLeads } from "../services/api";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { Link } from "react-router-dom";
import MyTasks from "./MyTasks";
import { ChartPieIcon, UserGroupIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/outline';


const Homepage = ({ authToken }) => {
  const [leadsByStatus, setLeadsByStatus] = useState([]);
  const [allLeads, setAllLeads] = useState([]);
  const [leadConversionRate, setLeadConversionRate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        const leadsResponse = await getLeads(authToken);
        const leads = leadsResponse.results

        // Process leads by status
        const statusCounts = leads.reduce((acc, lead) => {
          acc[lead.status] = (acc[lead.status] || 0) + 1;
          return acc;
        }, {});

        const leadStatusData = Object.entries(statusCounts).map(
          ([status, count]) => ({
            name: status,
            value: count,
          })
        );

        setLeadsByStatus(leadStatusData);
        setAllLeads(leads);

        // Calculate lead conversion rate
        const convertedLeads = leads.filter(
          (lead) => lead.status === "converted"
        ).length;
        const conversionRate = (convertedLeads / leads.length) * 100;
        setLeadConversionRate(conversionRate.toFixed(2));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setError("Failed to fetch dashboard data. Please try again.");
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [authToken]);

  // const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884D8"];
  const COLORS = ["#3B82F6", "#10B981", "#F59E0B", "#EF4444", "#8B5CF6"];
  const statuses = ["new", "contacted", "qualified", "lost", "converted"];

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "new":
        return "bg-blue-100 text-blue-800";
      case "contacted":
        return "bg-yellow-100 text-yellow-800";
      case "qualified":
        return "bg-green-100 text-green-800";
      case "lost":
        return "bg-red-100 text-red-800";
      case "converted":
        return "bg-purple-100 text-purple-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  if (loading)
    return <div className="text-center py-8">Loading dashboard...</div>;
  if (error)
    return <div className="text-center py-8 text-red-600">{error}</div>;

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Dental Practice Dashboard</h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {/* Leads by Status */}
          <div className="bg-white p-6 rounded-xl shadow-md">
            <div className="flex items-center mb-4">
              <ChartPieIcon className="h-6 w-6 text-blue-500 mr-2" />
              <h2 className="text-xl font-semibold text-gray-800">Leads by Status</h2>
            </div>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={leadsByStatus}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {leadsByStatus.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>

          {/* Lead Conversion Rate */}
          <div className="bg-white p-6 rounded-xl shadow-md">
            <div className="flex items-center mb-4">
              <ArrowTrendingUpIcon className="h-6 w-6 text-green-500 mr-2" />
              <h2 className="text-xl font-semibold text-gray-800">Conversion Rate</h2>
            </div>
            <div className="flex items-center justify-center h-32">
              <div className="text-5xl font-bold text-green-600">{leadConversionRate}%</div>
            </div>
          </div>

          {/* Total Leads */}
          <div className="bg-white p-6 rounded-xl shadow-md">
            <div className="flex items-center mb-4">
              <UserGroupIcon className="h-6 w-6 text-indigo-500 mr-2" />
              <h2 className="text-xl font-semibold text-gray-800">Total Leads</h2>
            </div>
            <div className="flex items-center justify-center h-32">
              <div className="text-5xl font-bold text-indigo-600">{allLeads.length}</div>
            </div>
          </div>
        </div>

        {/* My Tasks Section */}
        <div className="bg-white p-6 rounded-xl shadow-md mb-8">
          <MyTasks authToken={authToken} />
        </div>

        {/* Leads Table */}
        <div className="bg-white p-6 rounded-xl shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">Leads Overview</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {["Name", "Source", "Email", "Phone", "Status", "Created at"].map((header) => (
                    <th key={header} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {statuses.map((status) => (
                  <React.Fragment key={status}>
                    <tr className="bg-gray-50">
                      <td colSpan="6" className="px-6 py-4 font-medium text-gray-900">
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </td>
                    </tr>
                    {allLeads
                      .filter((lead) => lead.status === status)
                      .map((lead) => (
                        <tr key={lead.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap">
                            <Link to={`/leads/${lead.id}`} className="text-indigo-600 hover:text-indigo-900">
                              {lead.first_name} {lead.last_name}
                            </Link>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">{lead.source}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{lead.email}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{lead.phone}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(lead.status)}`}>
                              {lead.status}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">{new Date(lead.created_at).toLocaleDateString()}</td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
