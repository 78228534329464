import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, isAuthenticated }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      const timer = setTimeout(() => {
        console.log("Redirecting to login...");
        setShouldRedirect(true);
      }, 100); // Small delay to allow for state updates
      return () => clearTimeout(timer);
    }
  }, [isAuthenticated]);

  if (shouldRedirect) {
    return <Navigate to="/login" replace />;
  }

  if (!isAuthenticated) {
    return <div>Checking authentication...</div>;
  }

  console.log("Rendering protected content...");
  return children;
};

export default PrivateRoute;