import React, { useState, useEffect, useCallback } from "react";
import { getUserNotes } from "../services/api";
import NoteDetail from "./NoteDetail";
import { PlusIcon, ArrowPathIcon } from '@heroicons/react/24/outline';

const MyNotes = ({ authToken }) => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedNoteId, setSelectedNoteId] = useState(null);

  const fetchNotes = useCallback(async () => {
    if (!authToken) return;

    try {
      setLoading(true);
      const fetchedNotes = await getUserNotes(authToken);
      setNotes(fetchedNotes);
      setError(null);
    } catch (error) {
      console.error("Error fetching user notes:", error);
      setError("Failed to fetch notes. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [authToken]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  const handleNoteClick = (noteId) => {
    setSelectedNoteId(noteId);
  };
  const handleNoteUpdated = (updatedNote) => {
    setNotes(
      notes.map((note) => (note.id === updatedNote.id ? updatedNote : note))
    );
  };
  const handleNoteDeleted = (deletedNoteId) => {
    setNotes(notes.filter((note) => note.id !== deletedNoteId));
    setSelectedNoteId(null);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">My Notes</h1>
        <div className="flex space-x-2">
          <button
            onClick={fetchNotes}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md flex items-center"
          >
            <ArrowPathIcon className="h-5 w-5 mr-2" />
            Refresh
          </button>
          <button className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md flex items-center">
            <PlusIcon className="h-5 w-5 mr-2" />
            New Note
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden col-span-1">
          <div className="bg-gray-100 px-4 py-3 border-b">
            <h2 className="text-lg font-semibold text-gray-800">Note List</h2>
          </div>
          <div className="divide-y divide-gray-200 max-h-[calc(100vh-200px)] overflow-y-auto">
            {notes.length === 0 ? (
              <p className="p-4 text-gray-500">
                You haven't written any notes yet.
              </p>
            ) : (
              notes.map((note) => (
                <div
                  key={note.id}
                  className={`p-4 cursor-pointer transition duration-150 ease-in-out ${
                    selectedNoteId === note.id
                      ? "bg-blue-50"
                      : "hover:bg-gray-50"
                  }`}
                  onClick={() => handleNoteClick(note.id)}
                >
                  <p className="font-medium text-gray-800 mb-1">
                    {note.content.substring(0, 50)}...
                  </p>
                  <p className="text-sm text-gray-500">
                    {new Date(note.created_at).toLocaleString()}
                  </p>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="bg-white shadow-lg rounded-lg overflow-hidden col-span-2">
          <div className="bg-gray-100 px-4 py-3 border-b">
            <h2 className="text-lg font-semibold text-gray-800">
              Note Details
            </h2>
          </div>
          <div className="p-4">
            {selectedNoteId ? (
              <NoteDetail
                authToken={authToken}
                noteId={selectedNoteId}
                onNoteUpdated={handleNoteUpdated}
                onNoteDeleted={handleNoteDeleted}
              />
            ) : (
              <p className="text-gray-500">Select a note to view details</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyNotes;
