import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const CompanyCreate = ({ authToken }) => {
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${config.BASE_API_ENDPOINT}/api/companies/`, { name }, {
        headers: { Authorization: `Token ${authToken}` }
      });
      navigate('/companies');
    } catch (error) {
      console.error('Error creating company:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Create Company</h2>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Company Name"
        required
      />
      <button type="submit">Create</button>
    </form>
  );
};

export default CompanyCreate;