import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

const DocumentDetail = ({ documentId, token, onClose }) => {
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const API_URL = `${config.BASE_API_ENDPOINT}/api/`;
        const response = await axios.get(`${API_URL}documents/${documentId}/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        setDocument(response.data);
      } catch (err) {
        setError('Failed to fetch document details. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [documentId, token]);

  if (loading) return <div className="text-center">Loading document details...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!document) return <div className="text-center">No document found.</div>;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-md leading-6 font-medium text-gray-900">{document.document_type}</h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500">
              Uploaded on: {new Date(document.created_at).toLocaleString()}
            </p>
            <p className="text-sm text-gray-500 mt-1">
              Uploaded by: {document.created_by}
            </p>
            <div className="mt-4">
              <a 
                href={document.file} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Download/View File
              </a>
            </div>
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="ok-btn"
              className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentDetail;