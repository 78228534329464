import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createAppointment, getStaffForPatient } from '../services/api';

const AppointmentCreate = ({ authToken }) => {
  const { patientId } = useParams();
  const navigate = useNavigate();
  const [appointment, setAppointment] = useState({
    date_time: '',
    type: '',
    status: '',
    staff_id: '',
    duration: ''
  });
  const [error, setError] = useState(null);
  const [staffList, setStaffList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchStaff = useCallback(async () => {
      try {
        setLoading(true);
        setError(null)
        const staff = await getStaffForPatient(authToken, patientId);
        setStaffList(staff);
      } catch (err) {
        console.error("error fetching", err)
      } finally {
        setLoading(false);
      }
  }, [authToken, patientId]);

  useEffect(() =>{
    fetchStaff();
  }, [fetchStaff]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppointment(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createAppointment(authToken, patientId, appointment);
      navigate(`/patients/${patientId}/appointments`);
    } catch (err) {
      setError('Failed to create appointment. Please try again.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-xl font-bold mb-4">Create New Appointment</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="date_time" className="block text-sm font-medium text-gray-700">Date & Time</label>
          <input
            type="datetime-local"
            id="date_time"
            name="date_time"
            value={appointment.date_time}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div>
          <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
          <input
            type="text"
            id="type"
            name="type"
            value={appointment.type}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div>
          <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
          <select
            id="status"
            name="status"
            value={appointment.status}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          >
            <option value="">Select status</option>
            <option value="scheduled">Scheduled</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
        <div>
          <label htmlFor="staff_id" className="block text-sm font-medium text-gray-700">Staff</label>
          <select
            id="staff_id"
            name="staff_id"
            value={appointment.staff_id}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          >
            <option value="">Select staff</option>
            {staffList.map((staff) => (
              <option key={staff.id} value={staff.id}>
                {staff.user.username} - {staff.role.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="duration" className="block text-sm font-medium text-gray-700">Duration (minutes)</label>
          <input
            type="number"
            id="duration"
            name="duration"
            value={appointment.duration}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <button type="submit" className="px-4 py-2 bg-btn-primary text-white rounded hover:bg-btn-primary-hover">Create Appointment</button>
        </div>
      </form>
    </div>
  );
};

export default AppointmentCreate;