import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';

const Signup = ({ onSignup }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.BASE_API_ENDPOINT}/api/signup/`, { email, password });
      onSignup(response.data.token);
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  return (
    <form onSubmit={handleSignup} className="max-w-lg mx-auto p-4 shadow-md rounded-md bg-white">
      <h2 className="text-xl font-bold mb-4">Sign Up</h2>
      <div className="mb-4">
        <label className="block text-gray-700">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <button type="submit" className="w-full bg-green-500 text-white p-2 rounded-md hover:bg-green-600">
        Sign Up
      </button>
    </form>
  );
};

export default Signup;