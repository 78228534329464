import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DocumentDetail from './DocumentDetail';
import { getDocuments } from '../services/api.js';

const DocumentList = ({ patientId, token }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedDocument, setSelectedDocument] = useState(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const documents = await getDocuments(token, patientId);
        setDocuments(documents);
      } catch (err) {
        setError('Failed to fetch documents. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchDocuments();
  }, [patientId, token]);

  if (loading) return <div className="text-center">Loading documents...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="mt-8">
      {selectedDocument && (
        <DocumentDetail 
          documentId={selectedDocument} 
          token={token} 
          onClose={() => setSelectedDocument(null)}
        />
      )}
      <h2 className="text-xl font-bold mb-4">Patient Documents</h2>
      {documents.length === 0 ? (
        <p>No documents found for this patient.</p>
      ) : (
        <ul className="space-y-4">
          {documents.map((doc) => (
            <li key={doc.id} className="border p-4 rounded-lg shadow">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="font-semibold">{doc.document_type}</h3>
                  <p className="text-sm text-gray-500">Uploaded on: {new Date(doc.created_at).toLocaleDateString()}</p>
                </div>
                <a 
                  href={doc.file} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  View
                </a>
              </div>
              <button 
                onClick={() => setSelectedDocument(doc.id)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                View
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DocumentList;