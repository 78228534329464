import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getCompany } from '../services/api';
import ServiceList from './ServiceList';

const CompanyDetail = ({ authToken }) => {
  const [company, setCompany] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        setLoading(true)
        const data = await getCompany(authToken, id)
        setCompany(data);
        setLoading(false)
      } catch (error) {
        console.error('Error fetching company:', error);
        setLoading(false)
      }
    };
    fetchCompany();
  }, [authToken, id]);

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (!company) return <div className="text-center py-4">Company not found</div>;


  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-bold mb-4">{company.name}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600"><span className="font-semibold">Type:</span> {company.company_type}</p>
            <p className="text-gray-600"><span className="font-semibold">Address:</span> {company.address}</p>
          </div>
          <div>
            <p className="text-gray-600"><span className="font-semibold">Phone:</span> {company.phone}</p>
            <p className="text-gray-600"><span className="font-semibold">Email:</span> {company.email}</p>
          </div>
        </div>
        <div className="mt-6">
          <Link 
            to={`/companies/${id}/edit`}
            className="bg-btn-primary hover:bg-btn-primary-hover text-sm font-medium text-white py-2 px-4 rounded"
          >
            Edit Company
          </Link>
        </div>
      </div>

      <div className="mt-8">
        <ServiceList authToken={authToken} companyId={id} />
      </div>
    </div>
  );
};

export default CompanyDetail;