import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCompanies } from '../services/api.js';

const CompanyList = ({ authToken }) => {
  const [companies, setCompanies] = useState([]);
  const [fields, setFields] = useState([
    { key: 'name', label: 'Company Name' },
    { key: 'type', label: 'Type' },
    { key: 'address', label: 'Address' },
    { key: 'phone', label: 'Phone' },
    { key: 'email', label: 'Email' },
  ]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companies = await getCompanies(authToken);
        setCompanies(companies);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };
    fetchCompanies();
  }, [authToken]);

  return (
  <div className="container mx-auto px-2 sm:px-4 py-8">
    <div className="flex justify-between items-center mb-6">
      <h2 className="text-2xl font-bold text-gray-800">Companies</h2>
      <Link 
        to="/companies/create" 
        className="bg-btn-primary hover:bg-btn-primary-hover text-sm font-medium text-white py-2 px-4 rounded transition duration-300"
      >
        Create New Company
      </Link>
    </div>
    <div className="bg-white shadow-md rounded-lg overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
          {fields.map(field => (
            <th key={field.key} className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
              {field.label}
            </th>
          ))}
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {companies.map(company => (
            <tr key={company.id} className="hover:bg-gray-50">
              {fields.map(field => (
                <td key={`${company.id}-${field.key}`} className="px-6 py-4 whitespace-normal">
                  <div className="text-sm text-gray-500">{company[field.key]}</div>
                </td>
              ))}
              <td className="px-6 py-4 whitespace-normal text-sm font-medium">
                <Link to={`/companies/${company.id}`} className="text-indigo-600 hover:text-indigo-900 mr-3">View</Link>
                <Link to={`/companies/${company.id}/edit`} className="text-blue-600 hover:text-blue-900">Edit</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);
};

export default CompanyList;