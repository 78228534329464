import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getAppointments } from '../services/api';

const AppointmentList = ({ authToken }) => {
const [appointments, setAppointments] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);
const { patientId } = useParams();

const fetchAppointments = useCallback(async () => {
      try {
        setLoading(true);
        setError(null);
        const fetchedAppointments = await getAppointments(authToken, patientId);
        setAppointments(fetchedAppointments);
      } catch (err) {
        // setError('Failed to fetch appointments. Please try again.');
        console.error('Error fetching services', err)
      } finally {
        setLoading(false);
      }
  }, [authToken, patientId]);

useEffect(() => {
  fetchAppointments();
}, [fetchAppointments]);

  if (loading) return <p className="text-center py-4">Loading appointments...</p>;
  if (error) return <p className="text-center py-4 text-red-500">{error}</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold">Appointments</h2>
        <Link 
          to={`/patients/${patientId}/appointments/new`}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add New Appointment
        </Link>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Date & Time</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Type</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Staff</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {appointments.map((appointment) => (
              <tr key={appointment.id}>
                <td className="px-6 py-4 whitespace-nowrap">{new Date(appointment.date_time).toLocaleString()}</td>
                <td className="px-6 py-4 whitespace-nowrap">{appointment.type}</td>
                <td className="px-6 py-4 whitespace-nowrap">{appointment.status}</td>
                <td className="px-6 py-4 whitespace-nowrap">{appointment.staff ? appointment.staff.user.username : 'Not assigned'}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <Link 
                    to={`/patients/${patientId}/appointments/${appointment.id}/edit`}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AppointmentList;