import React, { useState } from 'react';
import { createNote } from '../services/api';

const NoteCreate = ({ authToken, contentType, patientId, onNoteCreated }) => {
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content.trim()) {
      setError('Note content cannot be empty');
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      await createNote(authToken, contentType, patientId, { content });
      setContent('');
      if (onNoteCreated) onNoteCreated();
    } catch (error) {
      console.error('Error creating note:', error);
      setError('Failed to create note. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-4">
      <h3 className="text-lg font-bold mb-2">Create New Note</h3>
      {error && (
        <div className="text-red-500 mb-2">{error}</div>
      )}
      <form onSubmit={handleSubmit}>
        <textarea
          className="w-full p-2 border rounded"
          rows="4"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Enter your note here..."
        ></textarea>
        <button
          type="submit"
          className="mt-2 bg-btn-primary hover:bg-btn-primary-hover text-sm font-medium text-white py-2 px-4 rounded"
          disabled={isLoading}
        >
          {isLoading ? 'Creating...' : 'Create Note'}
        </button>
      </form>
    </div>
  );
};

export default NoteCreate;