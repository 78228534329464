import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-800 border-t border-gray-200 mt-auto">
      <div className="container mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-gray-100 text-sm">
            © {new Date().getFullYear()} DenteraTech.com. All rights reserved.
          </div>
          <div className="mt-4 md:mt-0">
            <nav className="flex space-x-4">
              <a
                href="/privacy"
                className="text-gray-100 hover:text-gray-50 text-sm"
              >
                Privacy Policy
              </a>
              <a
                href="/terms"
                className="text-gray-100 hover:text-gray-50 text-sm"
              >
                Terms of Service
              </a>
              <a
                href="/contact"
                className="text-gray-100 hover:text-gray-50 text-sm"
              >
                Contact Us
              </a>
            </nav>
          </div>
          <div className="mt-4 md:mt-0 flex space-x-4">
            {/* Add social media icons here if desired */}
            <a href="#" className="text-gray-100 hover:text-gray-50">
              <span className="sr-only">Facebook</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
            {/* Add more social media icons as needed */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
