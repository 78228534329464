import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from "react-router-dom";
import {getServices} from '../services/api'

const ServiceList = ({ authToken, companyId: propCompanyId }) => {
  const { companyId: urlCompanyId } = useParams();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const effectiveCompanyId = propCompanyId || urlCompanyId


  const fetchServices = useCallback(async (retryCount = 0) => {
      try {
        setLoading(true);
        setError(null);
        const fetchedServices = await getServices(authToken, effectiveCompanyId);
        setServices(fetchedServices);
      } catch (err) {
        setError('Failed to fetch services. Please try again.');
        console.error('Error fetching services', err)
        if (retryCount < 1) {
          console.log('Retrying...');
          setTimeout(() => fetchServices(retryCount + 1), 1000); 
        } else {
          setError('Failed to fetch services');
        }
      } finally {
        setLoading(false);
      }
  }, [effectiveCompanyId, authToken]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  if (loading) return <p>Loading services...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-xl font-bold mb-6">Services</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Description</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Default Cost</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {services.map((service) => (
              <tr key={service.id}>
                <td className="px-6 py-4 whitespace-nowrap">{service.name}</td>
                <td className="px-6 py-4">{service.description}</td>
                <td className="px-6 py-4 whitespace-nowrap">${service.default_cost}</td>
                <td className="px-6 py-4 whitespace-normal text-sm font-medium">
                  <Link to={`/companies/${effectiveCompanyId}/services/${service.id}/edit/`} className="text-blue-600 hover:text-blue-900" >
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServiceList;