import React, { useState, useEffect } from 'react';
import { getTask, updateTask, deleteTask } from '../services/api';
import { useParams } from "react-router-dom";

const TaskDetail = ({ authToken, taskId: propTaskId, onTaskUpdated, onTaskDeleted }) => {
  const {taskId: urlTaskId} = useParams();
  const [task, setTask] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const [editedTask, setEditedTask] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const effectiveTaskId = propTaskId || urlTaskId;

  useEffect(() => {
    const fetchTask = async () => {
      if (!effectiveTaskId ) {
        setError('Task ID required');
        return;
      }

      setIsLoading(true);
      setError(null);
      try {
        const fetchedTask = await getTask(authToken, effectiveTaskId);
        setTask(fetchedTask);
        setEditedTask(fetchedTask);
        // setEditedContent(fetchedTask.content);
      } catch (error) {
        console.error('Error fetching task:', error);
        setError('Failed to fetch task. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTask();
  }, [authToken, effectiveTaskId]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedContent(task.content);
  };

  const handleSave = async () => {
    if (!editedContent.trim()) {
      setError('Task content cannot be empty');
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      const updatedTask = await updateTask(authToken, effectiveTaskId, { content: editedContent });
      setTask(updatedTask);
      setIsEditing(false);
      if (onTaskUpdated) onTaskUpdated(updatedTask);
    } catch (error) {
      console.error('Error updating task:', error);
      setError('Failed to update task. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedTask(prev => ({ ...prev, [name]: value }));
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this task?')) {
      setIsLoading(true);
      setError(null);
      try {
        await deleteTask(authToken, effectiveTaskId);
        if (onTaskDeleted) onTaskDeleted(effectiveTaskId);
      } catch (error) {
        console.error('Error deleting task:', error);
        setError('Failed to delete task. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isLoading) return <div className="mt-4">Loading...</div>;
  if (error) return <div className="mt-4 text-red-500">{error}</div>;
  if (!task) return null;

  return (
    <div className="mt-4 bg-white shadow-md rounded-lg p-4">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-bold">Task Details</h3>
        {!isEditing && (
          <div>
            <button
              onClick={handleEdit}
              className="bg-blue-500 hover:bg-blue-700 text-sm font-medium text-white py-1 px-2 rounded mr-2"
            >
              Edit
            </button>
            <button
              onClick={handleDelete}
              className="bg-red-500 hover:bg-red-700 text-sm font-medium text-white font-bold py-1 px-2 rounded"
            >
              Delete
            </button>
          </div>
        )}
      </div>
      {isEditing ? (
        <div>
          <input
            type="text"
            name="title"
            value={editedTask.title}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mb-2"
            placeholder="Title"
          />
          <textarea
            name="description"
            value={editedTask.description}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mb-2"
            rows="4"
            placeholder="Description"
          ></textarea>
          <input
            type="date"
            name="due_date"
            value={editedTask.due_date}
            onChange={handleInputChange}
            className="w-full p-2 border rounded mb-2"
          />
          <div className="mt-2">
            <button
              onClick={handleSave}
              className="bg-green-500 hover:bg-green-700 text-sm font-medium text-white py-2 px-4 rounded mr-2"
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Save'}
            </button>
            <button
              onClick={handleCancelEdit}
              className="bg-gray-500 hover:bg-gray-700 text-sm font-medium text-white py-2 px-4 rounded"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-gray-700"><span className="text-black font-bold">Title: </span>{task.title}</p>
          <p className="text-gray-700"><span className="text-black font-bold">Description: </span>{task.description}</p>
          <p className="text-gray-700"><span className="text-black font-bold">Due date: </span>{task.due_date}</p>
          <p className="text-sm text-gray-500 mt-2">
            Created by: {task.created_by} on {new Date(task.created_at).toLocaleString()}
          </p>
        </div>
      )}
    </div>
  );
};

export default TaskDetail;