import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createService } from '../services/api';

const ServiceCreate = ({ authToken }) => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState({ name: '', description: '', default_cost: '' });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setService(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createService(authToken, companyId, service);
      navigate(`/companies/${companyId}`);
    } catch (err) {
      setError('Failed to create service. Please try again.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-xl font-bold mb-4">Create New Service</h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={service.name}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            id="description"
            name="description"
            value={service.description}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows="3"
          ></textarea>
        </div>
        <div>
          <label htmlFor="default_cost" className="block text-sm font-medium text-gray-700">Default Cost</label>
          <input
            type="number"
            id="default_cost"
            name="default_cost"
            value={service.default_cost}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div>
          <button type="submit" className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">Create Service</button>
        </div>
      </form>
    </div>
  );
};

export default ServiceCreate;