import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../config';

const CompanyEdit = ({ authToken }) => {
  const [name, setName] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await axios.get(`${config.BASE_API_ENDPOINT}/api/companies/${id}/`, {
          headers: { Authorization: `Token ${authToken}` }
        });
        setName(response.data.name);
      } catch (error) {
        console.error('Error fetching company:', error);
      }
    };
    fetchCompany();
  }, [id, authToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${config.BASE_API_ENDPOINT}/api/companies/${id}/`, { name }, {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      navigate(`/companies/${id}`);
    } catch (error) {
      console.error('Error updating company:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Edit Company</h2>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Company Name"
        required
      />
      <button type="submit">Update</button>
    </form>
  );
};

export default CompanyEdit;