import { Switch } from "@headlessui/react";
import React, { useState, useEffect, useCallback } from "react";
import { getUserTasks } from "../services/api";
import { Link } from "react-router-dom";

const MyTasks = ({ authToken }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAllTasks, setShowAllTasks] = useState(false);

  const fetchTasks = useCallback(async () => {
    if (!authToken) return;

    try {
      setLoading(true);
      const fetchedTasks = await getUserTasks(authToken);
      setTasks(fetchedTasks);
      setError(null);
    } catch (error) {
      console.error("Error fetching user tasks:", error);
      setError("Failed to fetch tasks. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [authToken]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  const getUpcomingTasks = () => {
    const today = new Date();
    const sevenDaysLater = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);

    return tasks
      .filter((task) => {
        const dueDate = new Date(task.due_date);
        return dueDate >= today && dueDate <= sevenDaysLater;
      })
      .sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
  };

  const displayedTasks = showAllTasks ? tasks : getUpcomingTasks();

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div>
      <div className="flex items-center justify-between mb-4 text-base">
        <div className="flex items-center">
          <Switch
            checked={showAllTasks}
            onChange={setShowAllTasks}
            className={`${
              showAllTasks ? "bg-blue-600" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
          >
            <span
              className={`${
                showAllTasks ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
            />
          </Switch>
          <span className="ml-3 text-md font-medium text-gray-900">
            {showAllTasks ? "Showing All Tasks" : "Showing Upcoming Tasks"}
          </span>
        </div>
        <span className="text-sm text-gray-500">
          Showing {displayedTasks.length} task
          {displayedTasks.length !== 1 ? "s" : ""}
        </span>
      </div>
      {displayedTasks.length === 0 ? (
        <p className="text-gray-500">No tasks to display.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  Description
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  Due Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  Assigned To
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  Created By
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {displayedTasks.map((task) => (
                <tr key={task.id}>
                  <td className="px-6 py-4 whitespace-normal">
                    <Link
                      to={`/tasks/${task.id}`}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      {task.title}
                    </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                    {task.description}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(task.due_date).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {task.assigned_to_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {task.created_by}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 inline-flex text-sm leading-5 font-semibold rounded-full ${
                        task.status === "completed"
                          ? "bg-green-100 text-green-800"
                          : "bg-yellow-100 text-yellow-800"
                      }`}
                    >
                      {task.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MyTasks;
