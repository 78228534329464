import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getPatient } from '../services/api';
import NotesList from './NotesList';
import TasksList from './TasksList';
import DocumentUpload from './DocumentUpload'
import DocumentList from './DocumentList'

const PatientDetail = ({ authToken }) => {
  const [patient, setPatient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [notes, setNotes] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchPatient = async () => {
      if (!id) {
        setError('Invalid patient ID');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const data = await getPatient(authToken, id);
        setPatient(data);
        setLoading(false);
        setError(null);
      } catch (err) {
        console.error('Error fetching patient:', err);
        setError('Failed to fetch patient details. Please try again.');
        setLoading(false);
      }
    };

    fetchPatient();
  }, [authToken, id]);

  if (loading) return <div className="text-center py-8">Loading patient details...</div>;
  if (error) return <div className="text-center py-8 text-red-600">{error}</div>;
  if (!patient) return <div className="text-center py-8">No patient found.</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Patient Details</h2>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Personal Information</h3>
          <p>
            <strong>Name:</strong> {patient.first_name} {patient.last_name}
          </p>
          <p>
            <strong>Email:</strong> {patient.email}
          </p>
          <p>
            <strong>Phone:</strong> {patient.phone}
          </p>
          <p>
            <strong>Status:</strong> {patient.status}
          </p>
          <p>
            <strong>Source:</strong> {patient.source || "N/A"}
          </p>
        </div>

        {patient.address && (
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Address</h3>
            <p>{patient.address.street}</p>
            <p>
              {patient.address.city}, {patient.address.state}{" "}
              {patient.address.postal_code}
            </p>
            <p>{patient.address.country}</p>
          </div>
        )}

        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Additional Information</h3>
          <p>
            <strong>Location:</strong> {patient.location?.name || "N/A"}
          </p>
          {/* <p><strong>Total Value:</strong> ${patient.total_value?.toFixed(2) || '0.00'}</p> */}
          <p>
            <strong>Insurance Provider:</strong>{" "}
            {patient.insurance_provider?.name || "N/A"}
          </p>
          <p>
            <strong>Created At:</strong>{" "}
            {new Date(patient.created_at).toLocaleDateString()}
          </p>
          <p>
            <strong>Updated At:</strong>{" "}
            {new Date(patient.updated_at).toLocaleDateString()}
          </p>
        </div>

        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Patient Notes</h3>
          <NotesList authToken={authToken} patientId={id} />
        </div>

        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Patient Tasks</h3>
          <TasksList authToken={authToken} patientId={id} />
        </div>

        <div className="left mt-8">
          <DocumentUpload patientId={id} token={authToken} />
        </div>

        <div className="left mt-8">
          <DocumentList patientId={id} token={authToken} />
        </div>

        <div className="mt-6">
          <Link
            to={`/patients/${patient.id}/edit`}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
          >
            Edit Patient
          </Link>
          <Link
            to="/patients"
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Back to Patients List
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PatientDetail;