import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { getPatients } from "../services/api.js";

const PatientList = ({ authToken }) => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState("last_name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");

  const fields = [
    { key: "name", label: "Patient Name" },
    { key: "email", label: "Email" },
    { key: "phone", label: "Phone" },
    { key: "location", label: "Location" },
    { key: "status", label: "Status" },
    { key: "total_value", label: "Total Value" },
    { key: "created_at", label: "Created At" },
  ];

  const fetchPatients = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const fetchedPatients = await getPatients(authToken);
      setPatients(fetchedPatients);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching patients:", error);
      if (error.response && error.response.status !== 401) {
        setError("Failed to fetch patients. Please try again later.");
      }
      setLoading(false);
    }
  }, [authToken]);

  useEffect(() => {
    fetchPatients();
  }, [fetchPatients]);

  const handleSort = (field) => {
    setSortField(field);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedPatients = [...patients].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const filteredPatients = sortedPatients.filter((patient) =>
    fields.some((field) => {
      const value =
        field.key === "name"
          ? `${patient.last_name} ${patient.first_name}`
          : patient[field.key];
      return (
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
  );

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  if (loading) {
    return <div className="text-center py-8">Loading patients...</div>;
  }

  const formatCurrency = (value) => {
    if (typeof value === "number") {
      return `$${value.toFixed(2)}`;
    } else if (typeof value === "string" && !isNaN(parseFloat(value))) {
      return `$${parseFloat(value).toFixed(2)}`;
    }
    return "N/A";
  };

  return (
    <div className="container mx-auto px-2 sm:px-4 py-8">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4 sm:mb-0">
          Patients
        </h2>
        <div className="flex flex-col sm:flex-row items-center">
          <input
            type="text"
            placeholder="Search patients..."
            className="border rounded py-2 px-3 mb-2 sm:mb-0 sm:mr-2"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Link
            to="/patients/create"
            className="bg-accent hover:bg-accent-dark text-button-primary-text font-bold py-2 px-4 rounded transition duration-300"
          >
            Create New Patient
          </Link>
        </div>
      </div>
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
          <button
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => {
              setError(null);
              fetchPatients();
            }}
          >
            <span className="text-lg">&times;</span>
          </button>
        </div>
      )}
      <div className="bg-white shadow-md rounded-lg overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {fields.map((field) => (
                <th
                  key={field.key}
                  className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort(field.key)}
                >
                  {field.label}
                  {sortField === field.key && (
                    <span className="ml-1">
                      {sortDirection === "asc" ? "↑" : "↓"}
                    </span>
                  )}
                </th>
              ))}
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredPatients.length > 0 ? (
              filteredPatients.map((patient) => (
                <tr key={patient.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-normal">
                    <Link
                      to={`/patients/${patient.id}`}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      {`${patient.last_name}, ${patient.first_name}`}
                    </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-normal">
                    <div className="text-sm text-gray-500">
                      {patient.email || "N/A"}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-normal">
                    <div className="text-sm text-gray-500">
                      {patient.phone || "N/A"}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-normal">
                    <div className="text-sm text-gray-500">
                      {patient.location?.name || "N/A"}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-normal">
                    <div className="text-sm text-gray-500">
                      {patient.status || "N/A"}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-normal">
                    <div className="text-sm text-gray-500">
                      {formatCurrency(patient.total_value)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-normal">
                    <div className="text-sm text-gray-500">
                      {formatDate(patient.created_at)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-normal text-sm font-medium">
                    <Link
                      to={`/patients/${patient.id}/edit`}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={fields.length + 1}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                >
                  No patients found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PatientList;
