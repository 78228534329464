import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getService, updateService } from '../services/api';

const ServiceEdit = ({ authToken }) => {
  const { companyId, serviceId } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState({ name: '', description: '', default_cost: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

const fetchService = useCallback(async () => {
      try {
        console.log("serviceId:", serviceId)
        const fetchedService = await getService(authToken, companyId, serviceId);
        setService(fetchedService);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch service. Please try again.');
        setLoading(false);
      }
  }, [authToken, companyId, serviceId]);

  useEffect(() => {
    fetchService();
  }, [fetchService]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setService(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateService(authToken, companyId, serviceId, service);
      navigate(`/companies/${companyId}`);
    } catch (err) {
      setError('Failed to update service. Please try again.');
    }
  };

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error) return <div className="text-center py-4 text-red-500">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-xl font-bold mb-4">Edit Service</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={service.name}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            id="description"
            name="description"
            value={service.description}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows="3"
          ></textarea>
        </div>
        <div>
          <label htmlFor="default_cost" className="block text-sm font-medium text-gray-700">Default Cost</label>
          <input
            type="number"
            id="default_cost"
            name="default_cost"
            value={service.default_cost}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div>
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Update Service</button>
        </div>
      </form>
    </div>
  );
};

export default ServiceEdit;