import React, { useState, useEffect } from 'react';
import { getNote, updateNote, deleteNote } from '../services/api';
import { useParams } from "react-router-dom";

const NoteDetail = ({ authToken, noteId: propNoteId, onNoteUpdated, onNoteDeleted }) => {
  const {noteId: urlNoteId} = useParams();
  const [note, setNote] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const effectiveNoteId = propNoteId || urlNoteId;

  useEffect(() => {
    const fetchNote = async () => {
      if (!effectiveNoteId ) {
        setError('Note ID required');
        return;
      }

      setIsLoading(true);
      setError(null);
      try {
        const fetchedNote = await getNote(authToken, effectiveNoteId);
        setNote(fetchedNote);
        setEditedContent(fetchedNote.content);
      } catch (error) {
        console.error('Error fetching note:', error);
        setError('Failed to fetch note. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchNote();
  }, [authToken, effectiveNoteId]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedContent(note.content);
  };

  const handleSave = async () => {
    if (!editedContent.trim()) {
      setError('Note content cannot be empty');
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      const updatedNote = await updateNote(authToken, effectiveNoteId, { content: editedContent });
      setNote(updatedNote);
      setIsEditing(false);
      if (onNoteUpdated) onNoteUpdated(updatedNote);
    } catch (error) {
      console.error('Error updating note:', error);
      setError('Failed to update note. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      setIsLoading(true);
      setError(null);
      try {
        await deleteNote(authToken, effectiveNoteId);
        if (onNoteDeleted) onNoteDeleted(effectiveNoteId);
      } catch (error) {
        console.error('Error deleting note:', error);
        setError('Failed to delete note. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isLoading) return <div className="mt-4">Loading...</div>;
  if (error) return <div className="mt-4 text-red-500">{error}</div>;
  if (!note) return null;

  return (
    <div className="mt-4 bg-white shadow-md rounded-lg p-4">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-bold">Note Details</h3>
        {!isEditing && (
          <div>
            <button
              onClick={handleEdit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2"
            >
              Edit
            </button>
            <button
              onClick={handleDelete}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
            >
              Delete
            </button>
          </div>
        )}
      </div>
      {isEditing ? (
        <div>
          <textarea
            className="w-full p-2 border rounded"
            rows="4"
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
          ></textarea>
          <div className="mt-2">
            <button
              onClick={handleSave}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Save'}
            </button>
            <button
              onClick={handleCancelEdit}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-gray-700">{note.content}</p>
          <p className="text-sm text-gray-500 mt-2">
            Created by: {note.created_by} on {new Date(note.created_at).toLocaleString()}
          </p>
        </div>
      )}
    </div>
  );
};

export default NoteDetail;