import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getOpportunities } from '../services/api.js';

const OpportunityList = ({ authToken }) => {
  const [opportunities, setOpportunities] = useState([]);
  const [fields, setFields] = useState([
    { key: 'name', label: 'Name' },
    { key: 'description', label: 'Description' },
    { key: 'potential_revenue', label: 'Potential Revenue' },
    { key: 'stage', label: 'Stage' },
  ]);

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        console.log("opportunityList authToken:", authToken)
        const opportunities = await getOpportunities(authToken);
        setOpportunities(opportunities);
      } catch (error) {
        console.error('Error fetching opportunities:', error);
      }
    };
    fetchOpportunities();
  }, [authToken]);

  return (
  <div className="container mx-auto px-2 sm:px-4 py-8">
    <div className="flex justify-between items-center mb-6">
      <h2 className="text-2xl font-bold text-gray-800">Opportunities</h2>
      <Link 
        to="/opportunities/create" 
        className="bg-accent hover:bg-accent-dark text-button-primary-text font-bold py-2 px-4 rounded transition duration-300"
      >
        Create New Opportunity
      </Link>
    </div>
    <div className="bg-white shadow-md rounded-lg overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
          {fields.map(field => (
            <th key={field.key} className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
              {field.label}
            </th>
          ))}
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {opportunities.map(opportunity => (
            <tr key={opportunity.id} className="hover:bg-gray-50">
              {fields.map(field => (
                <td key={`${opportunity.id}-${field.key}`} className="px-6 py-4 whitespace-normal">
                  <div className="text-sm text-gray-500">{opportunity[field.key]}</div>
                </td>
              ))}
              <td className="px-6 py-4 whitespace-normal text-sm font-medium">
                <Link to={`/opportunities/${opportunity.id}`} className="text-indigo-600 hover:text-indigo-900 mr-3">View</Link>
                <Link to={`/opportunities/${opportunity.id}/edit`} className="text-blue-600 hover:text-blue-900">Edit</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);
};

export default OpportunityList;