const env = process.env.REACT_APP_ENV || 'development';

const config = {
  development: {
    BASE_API_ENDPOINT: 'http://localhost:8000',
  },
  staging: {
    BASE_API_ENDPOINT: 'https://staging.dentaratech.com',
  },
  production: {
    BASE_API_ENDPOINT: 'https://dentaratech.com',
  },
};

export default config[env];