import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { getLeadNotes, getPatientNotes } from "../services/api.js";
import NoteCreate from "./NoteCreate";


const NotesList = ({ authToken, id: propId}) => {
  // const { patientId: urlPatientId } = useParams();
  const { id: urlId } = useParams();
  const location = useLocation();
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [showNoteCreate, setShowNoteCreate] = useState(false);

  const effectiveId = propId || urlId;
  const isLead = location.pathname.includes('/leads/');
  const contentType = isLead ? 'leads' : 'patients'


  const fields = [
    { key: "created_by", label: "Created By" },
    { key: "content", label: "Content" },
    { key: "created_at", label: "Created At" },
  ];

  const fetchNotes = useCallback(async () => {
    if (!effectiveId) {
      setError("No patient ID provided");
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const getNotes = isLead ? getLeadNotes : getPatientNotes;
      const fetchedNotes = await getNotes(authToken, effectiveId);
      console.error("is lead?", isLead)
      setNotes(fetchedNotes);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching notes:", error);
      setError("Failed to fetch notes. Please try again later.");
      setLoading(false);
    }
  }, [authToken, effectiveId, isLead]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  const handleSort = (field) => {
    setSortField(field);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedNotes = [...notes].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const filteredNotes = sortedNotes.filter((note) =>
    fields.some((field) => {
      const value = note[field.key];
      return (
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
  );

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const handleNoteCreated = () => {
    fetchNotes();
    setShowNoteCreate(false);
  };

  if (loading) {
    return <div className="text-center py-8">Loading notes...</div>;
  }

  return (
    <div className="container mx-auto px-2 sm:px-4 py-8">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4 sm:mb-0">
          {isLead ? 'Lead' : 'Patient'} Notes
        </h2>
        <div className="flex flex-col sm:flex-row items-center">
          <input
            type="text"
            placeholder="Search notes..."
            className="border rounded py-2 px-3 mb-2 sm:mb-0 sm:mr-2"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            onClick={() => setShowNoteCreate(!showNoteCreate)}
            className="bg-btn-primary hover:bg-btn-primary-hover text-sm font-medium text-white py-2 px-4 rounded transition duration-300"
          >
            {showNoteCreate ? "Cancel" : "Create New Note"}
          </button>
        </div>
      </div>
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
          <button
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => {
              setError(null);
              fetchNotes();
            }}
          >
            <span className="text-lg">&times;</span>
          </button>
        </div>
      )}
      {showNoteCreate && (
        <NoteCreate
          authToken={authToken}
          contentType={contentType} 
          patientId={effectiveId}
          onNoteCreated={handleNoteCreated}
        />
      )}
      <div className="bg-white shadow-md rounded-lg overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {fields.map((field) => (
                <th
                  key={field.key}
                  className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort(field.key)}
                >
                  {field.label}
                  {sortField === field.key && (
                    <span className="ml-1">
                      {sortDirection === "asc" ? "↑" : "↓"}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredNotes.length > 0 ? (
              filteredNotes.map((note) => (
                <tr key={note.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-normal">
                    <Link
                      to={`/${contentType}/${effectiveId}/notes/${note.id}`}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      {note.created_by}
                    </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-normal">
                    <div className="text-sm text-gray-500">{note.content}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-normal">
                    <div className="text-sm text-gray-500">
                      {formatDate(note.created_at)}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={fields.length}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                >
                  No notes found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NotesList;